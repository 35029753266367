import { Box, Button } from "@mui/material";

import './Home.scss';
import waves from '../../assets/waves.mp4';
import chevRight from '../../assets/chevron-right.svg';
import infinity from '../../assets/infinity.png';
import globalMap from '../../assets/global.png'

import sec21 from '../../assets/sec-2-1.png';
import sec22 from '../../assets/sec-2-2.png';
import sec23 from '../../assets/sec-2-3.png';
import sec24 from '../../assets/sec-2-4.png';

function Home() {
	const secOneDesc = "Maximize the power of your data with Rezolv, the world's leading platform for data analytics. Gain unprecedented insights into your data, driving increased revenue, seamless customer experiences and optimised costs.";
	const secTwoDesc1 = "The financial services world has not harnessed the power of data - losing them significant revenue and customers. Rezolv is the only platform that is purposefully built as unified data platform.";

	const sec2Images = [sec21, sec22, sec23, sec24]
	const secThreeDesc = "With a multitude of data points at your disposal, harnessing the power of data analytics can drive your success, boost profits, and enhance customer retention."
	const secFourDesc = "Consider the possibilites unlocked by crystal-clear analytics."
	const scrollText = [
		"Gain instantaneous insight into your financial standing",
		"Understand customer behaviour patterns",
		"Harness the full potential of AI and deploy it at the forefront of operations.",
		"Address your most pressing challenges and seize opportunities previously beyond your grasp",
	];

	const watchVideo = () => {
		console.log("watch video")
	}
	const signUp = () => {
		console.log("signup")
	}
	const watchProductReview = () => {
		console.log("product review")
	}

	return (
		<>
			<Box className="home">
				<Box className="video-overlay">
					<video autoPlay loop muted>
						<source src={[waves]} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					<Box className="inset-overlay"></Box>
					<Box className="left-blur"></Box>
					<Box className="section-1 page-margin">
						<Box>
							<h1 className="d-flex">
								<span>THE WORLD'S </span>
								<span>STRONGEST UNIFIED </span>
								<span>DATA PLATFORM</span>
								<span>IS HERE.</span>
							</h1>
							<Button className="button second-button arrow-button" onClick={() => watchVideo()}>
								Watch the video
								<img src={chevRight} alt="chev icon" />
							</Button>
							<Box className="blue-line"></Box>
							<p className="sec-one-desc">{secOneDesc}</p>
							<Button className="button second-button arrow-button" onClick={() => signUp()}>
								Signup for early access
								<img src={chevRight} alt="chev icon" />
							</Button>
						</Box>
					</Box>
				</Box>
				<Box className="section-2 page-margin d-flex">
					<Box className="col-6 first-column">
						<img className="infinity-img" src={infinity} alt="infinity logo" />
						<h3 className="d-flex">
							<span>The journey has just begun.</span>
							<span>the financial services industry has yet to fully harness the potential of data augmentation.</span>
						</h3>
						<Box className="blue-line"></Box>
						<p className="sec-one-desc">{secTwoDesc1}</p>
						<Box className="d-flex button-wrap">
							<Button className="button second-button arrow-button" onClick={() => watchProductReview()}>
								Watch our Products Overview
								<img src={chevRight} alt="chev icon" />
							</Button>
							<Button className="button second-button arrow-button" onClick={() => signUp()}>
								Signup for early access
								<img src={chevRight} alt="chev icon" />
							</Button>
						</Box>
					</Box>
					<Box className="col-6 second-column d-flex">
						{sec2Images.map((url, index) => {
							return (
								<Box className="sec-img-wrap" key={index}>
									<Box className="sec-img" sx={{ backgroundImage: `url(${url})` }}></Box>
								</Box>
							)
						})}
					</Box>
				</Box>
				<Box className="section-3 page-margin">
					<Box className="latency-box col-6">
						<img src={infinity} className="infinity-img" />
						<h3>Unparalleled Data</h3>
						<Box className="blue-line"></Box>
						<p>{secThreeDesc}</p>
					</Box>
				</Box>
				<Box className="section-4 page-margin">
					<Box className="product-box-wrap d-flex">
						<Box className="product-box col-6 blur-bo">
							<img src={infinity} className="infinity-img" />
							<p>{secFourDesc}</p>
							<Box className="blue-line"></Box>
							<Button className="button second-button arrow-button" onClick={() => signUp()}>
								Products
								<img src={chevRight} alt="chev icon" />
							</Button>
						</Box>
						<Box className="product-bg">
							<img src={globalMap} alt="global map image" />
						</Box>
						<Box className="col-6 dummy-1"></Box>
					</Box>
					<Box className="scroll-wrap d-flex">
						<Box className="col-6"></Box>
						<Box className="scrolling-text-wrap col-6">
							{scrollText.map((text, index) => {
								return (
									<Box key={index} className="scrolling-text d-flex">
										<Box className="blue-line-wrap"><Box className="blue-line-thin" /></Box>
										<p>{text}</p>
									</Box>
								)
							})}
						</Box>
					</Box>
				</Box>
				<Box className="section-5 top-blur">
					<Box className="top-blur"></Box>
					<Box className="sec-5-content page-margin">
						<h1 className="d-flex">
							<span>REZOLV</span>
							<span>THE ULTIMATE</span>
							<span>DATA PLATFORM</span>
						</h1>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Home;
