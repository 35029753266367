import { Routes, Route } from "react-router-dom";

import "./App.scss";
import NotFound from "./pages/not-found/NotFound";
import Home from "./pages/home/Home";
import Header from "./components/header/Header";
import AboutUs from "./pages/about-us/AboutUs";
import WhyRezolv from "./pages/why-rezolv/WhyRezolv";
import Product from "./pages/product/Product";
import ContactUs from "./pages/contact-us/ContactUs";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/manifesto" element={<WhyRezolv />} />
        <Route path="/product" element={<Product />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </>
  );
}

export default App;
