import { NavLink } from "react-router-dom";

export const Navbar = () => {
	return (
		<nav className="navbar">
			{/* <NavLink className="nav-icon" to='/'>Home</NavLink> */}
			<NavLink className="nav-icon" to='/product'><span className="nav-icon-text">Products</span></NavLink>
			<NavLink className="nav-icon" to='/manifesto'><span className="nav-icon-text">Why Rezolv?</span></NavLink>
			<NavLink className="nav-icon" to='/about'><span className="nav-icon-text">About Us</span></NavLink>
			<NavLink className="nav-icon" to='/contact'><span className="nav-icon-text">Contact Us</span></NavLink>
		</nav>
	);
};