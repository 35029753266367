import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";

import "./Header.scss";
import { Navbar } from "../navbar/Navbar";

import logoImg from "../../assets/rezolv-logo.png";

function Header() {
  const navigate = useNavigate();

  const scheduleDemo = () => {
    console.log('schedule demo')
  }

  const navigateHome = () => {
    navigate('/');
  }

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition === 0) {
        setIsScrolled(false);
      } else {
        setIsScrolled(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box className={isScrolled ? 'header-wrap-container d-flex align-c blur' : 'header-wrap-container d-flex align-c'}>
      <Box className="d-flex header-wrapper">
        <Box className="logo-wrapper" onClick={() => navigateHome()}>
          <img className="logo-img" src={logoImg} alt="logo image"></img>
        </Box>
        <Navbar />
        <Button className="button prime-button schedule-demo-button" onClick={() => scheduleDemo()}>
          <span className="schedule-text">Schedule a demo</span>
        </Button>
      </Box>
    </Box>
  )
}

export default Header